import React from 'react';
import Config from '../config/Config';

let routes = {};

function parseItem(section, user) {
  if (!section.sidebarDisplay || !user) return;

  const children = [];
  const userGroups = user.signInUserSession.accessToken.payload['cognito:groups'];

  let accessRestricted = true;
  for (let i = 0; i < section.items.length; i++) {
    const nav = section.items[i];
    const child = { path: `/app${nav.path}`, name: nav.name };

    if (nav.hideFromSidebar) continue;
    if (userGroups?.includes('admins')) {
      children.push(child);
      accessRestricted = false;
      continue;
    }

    if (!nav.auth) continue;

    for (let i = 0; i < userGroups.length; i++) {
      const group = userGroups[i];
      if (nav.auth.includes(group)) accessRestricted = false;
    }

    if (accessRestricted) continue;
    else children.push(child);
  }

  if (accessRestricted) return;

  const route = { id: section.name, header: section.header, icon: <section.icon />, children: children };

  routes[section.name] = route;
}

const parseRoutes = user => {
  for (let i = 0; i < Config.Structure.length; i++) {
    const section = Config.Structure[i];
    parseItem(section, user);
  }

  return Object.values(routes);
};

export const sidebarRoutes = user => parseRoutes(user);
