import React from "react"
import styled, { createGlobalStyle } from "styled-components/macro"
import Providers from "./Providers"

import { withWidth } from "@material-ui/core"

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.palette.background.default};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`

const Root = styled.div`
  min-height: 100vh;
`

const Dashboard = ({ children, routes, width }) => {
  return (
    <Providers>
      <GlobalStyle />
      <Root>{children}</Root>
    </Providers>
  )
}

export default withWidth()(Dashboard)
