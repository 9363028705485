import React from "react"
import styled from "styled-components/macro"
import { Power } from "react-feather"
import { navigate } from "gatsby"
import { useDispatch } from "react-redux"
import { signOut } from "../redux/actions/authActions"

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@material-ui/core"
import { ROUTE_AUTH_LOGIN } from "../constants"

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`

function UserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null)
  const dispatch = useDispatch()

  const toggleMenu = event => {
    setAnchorMenu(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorMenu(null)
  }

  const handleSignOut = async () => {
    await dispatch(signOut())
    navigate(ROUTE_AUTH_LOGIN)
  }

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
        >
          <Power />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </React.Fragment>
  )
}

export default UserDropdown
