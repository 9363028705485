export default class TableManager {
  constructor() {
    this.filters = {};
    this.deepLink = false;
    this.lastPageData = undefined;
  }

  setFilters = filters => {
    this.filters = filters;
  };

  setDeepLink = deepLink => {
    this.deepLink = deepLink;
  };

  setLastPageData = data => {
    if (data) this.lastPageData = JSON.parse(data);
    else this.lastPageData = data;
  };
}
