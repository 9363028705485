import { Search } from './Search';
import { API, graphqlOperation } from 'aws-amplify';

async function initiateExportGeneration(props, context) {
  let mutation = `mutation ${props.data.export.apiCall}Mutation {
    ${props.data.export.apiCall} input ${props.data.export.apiReturnStructure}
  }`;

  if (context.filters) {
    let inputValues = '(input: { ';

    for (const filterKey in context.filters) {
      const filterValue = context.filters[filterKey];
      inputValues += `${filterKey}: ${typeof filterValue == 'string' ? `"${filterValue}" ` : filterValue}`;
    }

    inputValues += ` })`;

    mutation = mutation.replace(/input/g, inputValues);
  } else {
    mutation = mutation.replace(/input/g, '');
  }

  const res = await API.graphql(graphqlOperation(mutation, {})).catch(error => {
    console.log('GRAPHQL ERROR', error);
    alert('oeps! ', error);
  });

  console.log('export res', res);

  return res;
}

export default initiateExportGeneration;
