import React from "react"

import { Search } from "react-feather"
import Container from "@material-ui/core/Container"
import IconButton from "@material-ui/core/Button"
import InputBase from "@material-ui/core/InputBase"
import { withStyles } from "@material-ui/core/styles"

const SearchWrapper = withStyles(() => ({
  root: {
    position: "relative",
    margin: 0,
    padding: 0,
  },
}))(Container)

const StyledIconButton = withStyles(theme => ({
  root: {
    position: "absolute",
    display: "flex",
    margin: 0,
    top: "50%",
    right: theme.spacing(2),
    padding: theme.spacing(1.3),
    borderRadius: 20,
    minWidth: 20,
    maxWidth: 32,
    maxHeight: 32,
    transform: "translateY(-50%)",
    color: "#9D9D9D",
  },
}))(IconButton)

const SearchField = withStyles(theme => ({
  root: {
    marginLeft: theme.spacing(1),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    border: "1px solid #E0E0E0",
    borderRadius: 4,
    background: "#FFFFFF",
    width: 300,
    fontSize: 14,

    "&::placeholder": {
      color: "#9D9D9D",
    },
  },
}))(InputBase)

const WithSearch = () => {
  return (
    <SearchWrapper>
      <SearchField
        placeholder="Search by name or combi code"
        inputProps={{ "aria-label": "search google maps" }}
      />
      <StyledIconButton color="default" aria-label="search" component="div">
        <Search />
      </StyledIconButton>
    </SearchWrapper>
  )
}

export default WithSearch
