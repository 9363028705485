import React from "react"

import Button from "@material-ui/core/Button"
import { Link as NavLink } from "gatsby"

const WithButton = props => {
  return (
    <>
      {props.action.type === "button" && (
        <Button variant="contained" color="primary" size="large">
          {props.action.text ? props.action.text : ""}
        </Button>
      )}

      {props.action.type === "link" && (
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={NavLink}
          to={props.action.link}
        >
          {props.action.text ? props.action.text : ""}
        </Button>
      )}
    </>
  )
}

export default WithButton
