import Amplify from 'aws-amplify';
import Config from '../config/Config';
import { Router } from '@reach/router';
import awsconfig from '../aws-exports';
import { Provider } from 'react-redux';
import React, { useState } from 'react';
import store from '../redux/store/index';
import Layout from '../layouts/Dashboard';
import { CssBaseline } from '@material-ui/core';
import Page404 from '../components/pages/Page404';
import PrivateRoute from '../components/PrivateRoute';
import { TableContext } from '../contexts/TableContext';
import TableManager from '../components/Table/TableManager';
import PageTemplate from '../components/pages/PageTemplate';
import EmptyPage from '../components/pagetemplates/EmptyPage';

Amplify.configure(awsconfig);

const Page = ({ location }) => {
  const [tableManager, setFormManager] = useState(new TableManager());

  console.log('page', location);
  let pages = [];

  function parseItem(item) {
    // let subitems = []
    if (item.items) {
      item.items.forEach(subitem => {
        let component = subitem.component || EmptyPage;
        let headerTemplate = subitem.headerTemplate || 'default';
        let headerAction = subitem.headerAction || {};

        pages.push(
          <PrivateRoute
            path={'/app' + subitem.path}
            component={PageTemplate}
            title={subitem.name}
            innerComponent={component}
            headerTemplate={headerTemplate}
            headerAction={headerAction}
            key={'item' + subitem.name}
            data={subitem}
          />
        );
      });
    }

    if (item.path) {
      let component = item.component || EmptyPage;
      let headerTemplate = item.headerTemplate || 'default';
      let headerAction = item.headerAction || {};

      pages.push(
        <PrivateRoute
          path={'/app' + item.path}
          component={PageTemplate}
          title={item.name}
          innerComponent={component}
          headerTemplate={headerTemplate}
          headerAction={headerAction}
          key={'item' + item.name}
          data={item}
        />
      );
    }
  }

  Config.Structure.forEach(parseItem);

  const url = location.pathname ? location.pathname : '';
  const pathnames = location.pathname.split('/').filter(x => x);

  return (
    <TableContext.Provider value={tableManager}>
      <Provider store={store}>
        <CssBaseline />
        <Layout>
          <Router>
            {pages}
            <Page404 default />
          </Router>
        </Layout>
      </Provider>
    </TableContext.Provider>
  );
};

export default Page;
