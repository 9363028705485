import React from 'react';
import styled from 'styled-components/macro';
import { Link as NavLink } from 'gatsby';

import { Link, Breadcrumbs as MuiBreadcrumbs, Divider as MuiDivider, Typography, Button } from '@material-ui/core';

import { Add as AddIcon, Archive as ArchiveIcon, FilterList as FilterListIcon, RemoveRedEye as RemoveRedEyeIcon } from '@material-ui/icons';

import { spacing } from '@material-ui/system';

import WithSearch from './WithSearch';
import WithButton from './WithButton';
import WithDate from './WithDate';

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Header = styled.header`
  position: sticky;
  top: 64px;
  width: 100%;
  padding-top: 48px;
  padding-left: 48px;
  padding-right: 48px;
  background: #f7f9fc;
  z-index: 4;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const LeftCol = styled.div``;
const RightCol = styled.div``;

const PageHeader = props => {
  // console.log("props! pageheader",props);
  const pathnames = props.location.pathname.split('/').filter(x => x);
  return (
    <Header>
      <Wrapper>
        <LeftCol>
          <Typography variant="h3" gutterBottom display="inline">
            {props.variant !== 'withDate' ? props.title : `Welcome back, ${props.name}`}
          </Typography>

          {pathnames.length > 2 && (
            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
              {pathnames.map((pathname, index) => {
                let link = `/${pathnames[0]}`;
                if (index > 0 && index !== pathnames.length - 1) {
                  link += `/${pathnames[index]}`;
                  return (
                    <Link key={index} component={NavLink} to={`${link}`}>
                      {pathname}
                    </Link>
                  );
                } else if (index === pathnames.length - 1) {
                  return (
                    <Typography key={index} variant="body1">
                      {pathname}
                    </Typography>
                  );
                } else {
                  return false;
                }
              })}
              ))
            </Breadcrumbs>
          )}
        </LeftCol>
        <RightCol>
          {props.variant === 'withSearch' && <WithSearch />}
          {props.variant === 'withButton' && <WithButton action={props.headerAction} />}
          {props.variant === 'withDate' && <WithDate />}

          {props.data.data().options.indexOf('insert') != -1 && (
            <div>
              <Button variant="contained" color="primary" onClick={() => props.OpenRecord(0)}>
                <AddIcon />
                Nieuwe {props.data.data().nameSingle}
              </Button>
            </div>
          )}
          {props.data.data().options.indexOf('export') != -1 && (
            <div style={{ marginTop: '10px' }}>
              <Button variant="contained" color="primary" onClick={() => props.GenerateExport(props)}>
                <AddIcon />
                Nieuwe export maken
              </Button>
            </div>
          )}
        </RightCol>
      </Wrapper>

      <Divider my={6} />
    </Header>
  );
};

export default PageHeader;
