import React, { useState } from 'react';
import Header from '../components/AppBar';
import Sidebar from '../components/Sidebar';
import styled from 'styled-components/macro';
import { spacing } from '@material-ui/system';
import { isWidthUp } from '@material-ui/core/withWidth';
import { Hidden, Paper as MuiPaper, withWidth } from '@material-ui/core';

const Container = styled.div`
  display: flex;
`;

const drawerWidth = 258;

const Drawer = styled.div`
  ${props => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: auto;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${props => props.theme.palette.background.default};
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = ({ children, routes, width }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Container>
      <Drawer>
        <Hidden mdUp implementation="js">
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        </Hidden>
        <Hidden smDown implementation="css">
          <Sidebar routes={routes} PaperProps={{ style: { width: drawerWidth } }} />
        </Hidden>
      </Drawer>
      <AppContent>
        <Header onDrawerToggle={handleDrawerToggle} />
        <MainContent p={isWidthUp('lg', width) ? 12 : 5}>{children}</MainContent>
      </AppContent>
    </Container>
  );
};

export default withWidth()(Dashboard);
