// import { navigate } from "gatsby"
// import { isBrowser } from "../../utils"
// import { currentSession } from "../../redux/actions/authActions"
// import { useDispatch } from "react-redux"

const EmptyPage = () => {
  return "Empty Page"
}

export default EmptyPage
