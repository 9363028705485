import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import { isBrowser } from '../utils';
import { useDispatch } from 'react-redux';
import { ROUTE_AUTH_LOGIN } from '../constants';
import { currentSession } from '../redux/actions/authActions';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.authReducer.user);
  const [auth, setAuth] = useState(user ? true : false);

  if (!user && isBrowser()) {
    dispatch(currentSession()).then(result => {
      setAuth(result);
      if (!result) {
        navigate(ROUTE_AUTH_LOGIN);
      }
    });
  }

  if (!auth) {
    return null;
  } else {
    const userGroups = user?.signInUserSession?.accessToken?.payload['cognito:groups'] ?? [];
    if (userGroups.length > 0 && !userGroups.includes('admins')) {
      if (!rest.data.auth) return null;
      let accessRestricted = true;
      for (let i = 0; i < userGroups.length; i++) {
        const group = userGroups[i];
        if (rest.data.auth.includes(group)) accessRestricted = false;
      }

      if (accessRestricted) return null;
      else return <Component location={location} {...rest} />;
    }
  }

  return <Component location={location} {...rest} />;
};

export default PrivateRoute;
